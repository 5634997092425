import { Grid, styled, Typography } from '@mui/material'

import Button from './Button'
import CloseButton from './CloseButton'
import Color from './Color'

type AnnouncementProps = {
  backgroundColor: string
  callToActionText?: string
  className?: string
  message: string
  onClose?: () => void
  onClickCallToAction?: () => void
}

export const Announcement = styled(
  ({ callToActionText, className, message, onClose, onClickCallToAction }: AnnouncementProps) => {
    return (
      <Grid
        container
        component="dialog"
        className={className}
        alignItems="center"
        justifyContent="space-between"
        padding="0.5em 0.5em 0.5em 0.5em"
        minHeight="64px"
        sx={{ padding: { md: '0em 6em 0em 4em', xs: '0.5em 0.5em 0.5em 0.5em' } }}
      >
        <Grid item sx={{ display: 'flex', flex: '1' }}>
          <Typography
            variant="p2"
            m="0.5em 0"
            color={Color.saint.normal}
            maxWidth="80%"
            onClick={onClickCallToAction}
            sx={{ cursor: 'pointer' }}
          >
            <span dangerouslySetInnerHTML={{ __html: message }} />
          </Typography>
        </Grid>

        <Grid
          item
          sx={{
            alignItems: { md: 'stretch', xs: 'flex-end' },
            display: 'flex',
            flexDirection: { md: 'row', xs: 'column-reverse' },
          }}
        >
          {callToActionText && (
            <Button id="announcementBtn" onClick={onClickCallToAction} short small>
              {callToActionText}
            </Button>
          )}
          {onClose && <CloseButton onClose={() => onClose()} />}
        </Grid>
      </Grid>
    )
  }
)(({ backgroundColor }: AnnouncementProps) => ({
  backgroundColor: backgroundColor,
  overflow: 'hidden',
  position: 'relative',
  width: '100%',
}))

export default Announcement
