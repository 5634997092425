import React, { ReactNode, useContext, useState } from 'react'
import Grid from '@mui/material/Grid'
import Head from 'next/head'
import { StaticImageData } from 'next/image'
import { useRouter } from 'next/router'

import Seo from '@ecm/config/Seo'
import { getOrElse } from '@ecm/data/Maybe'
import { SeoPage } from '@ecm/data/Seo'
import { runClientEff } from '@ecm/effect'
import appRead from '@ecm/effect/app/Read'
import appTrack from '@ecm/effect/client/Track'
import { AnnouncementContext } from '@ecm/pages/_app'
import FinalCtaBlock from '@ecm/ui/component/block/FinalCta'
import Announcement from '@ecm/ui/element/Announcement'
import Color from '@ecm/ui/element/Color'
import useLayoutEffect from '@ecm/ui/hook/UseIsomorphicLayoutEffect'
import Footer from '@ecm/ui/layout/Footer'
import Header from '@ecm/ui/layout/Header'

type DefaultLayoutProps = {
  children: ReactNode
  className?: string
  externalHref?: string
  finalCta?: string
  finalCtaHeader?: string
  finalCtaSubHeader?: string
  gradientBackground?: boolean
  headerCta?: string
  headerCtaHref?: string
  hideFinalCta?: boolean
  hideHeader?: boolean
  hideLinks?: boolean
  hideMobileLinks?: boolean
  metaImageUrl?: string
  metaPermanentUrl?: string
  metaTwitterCard?: string
  metaType?: string
  noRobots?: boolean
  logo?: StaticImageData
  primaryHref?: string
  primaryOnClick?: () => void
  seoDescription?: string
  seoPage?: SeoPage
  seoTitle?: string
  canonicalUrl?: string
  headerBgColor?: string
  hideFooter?: boolean
}

export const DefaultLayout = ({
  children,
  className,
  externalHref,
  finalCta,
  finalCtaHeader,
  finalCtaSubHeader,
  gradientBackground,
  headerCta = 'Get started',
  headerCtaHref = '/pricing',
  hideFinalCta,
  hideHeader = false,
  hideLinks = false,
  hideMobileLinks = false,
  metaImageUrl,
  metaPermanentUrl,
  metaTwitterCard,
  metaType,
  noRobots,
  logo,
  primaryHref = '/pricing',
  primaryOnClick,
  seoDescription,
  seoPage,
  seoTitle,
  canonicalUrl,
  headerBgColor,
  hideFooter,
}: DefaultLayoutProps) => {
  const announcementContext = useContext(AnnouncementContext)
  const announcement = announcementContext.announcement

  const [showAnnouncement, setShowAnnouncement] = useState<boolean>(announcement.isActive)

  const router = useRouter()

  const onCloseAnnouncement = () => {
    setShowAnnouncement(false)
    runClientEff(eff => {
      eff.setStorageItem('hideAnnouncement', 'true')
      announcement.isActive = false
      announcementContext.setAnnouncementOff({ ...announcement })
    })
  }

  const onClickAnnouncement = () => {
    appTrack.trackGa({ event: 'clickedAnnouncement' })
    if (announcement?.callToActionRoute) {
      router.push(announcement.callToActionRoute).then(() => window.scrollTo(0, 0))
    }
  }

  useLayoutEffect(() => {
    runClientEff(eff => {
      setShowAnnouncement(
        !getOrElse(eff.getStorageItem('hideAnnouncement'), false) && announcement.isActive
      )
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const titleOrDefault = seoTitle || (seoPage && Seo[seoPage]?.title) || ''
  const descOrDefault = seoDescription || (seoPage && Seo[seoPage]?.description) || ''
  const metaImageUrlOrDefault = metaImageUrl || `${appRead.ask().appUrl}/images/og-share-img.png`
  const metaTypeOrDefault = metaType || 'article'
  const metaTwitterCardOrDefault = metaTwitterCard || 'summary'
  const canonicalPageUrl = canonicalUrl
    ? `${appRead.ask().appUrl}/${canonicalUrl}`
    : seoPage
    ? seoPage !== 'homepage'
      ? `${appRead.ask().appUrl}/${seoPage}`
      : `${appRead.ask().appUrl}`
    : ''
  const facebookVerificationKey = appRead.ask().facebookVerificationKey
  const googleVerificationKey = appRead.ask().googleVerificationKey

  return (
    <>
      <Head>
        <title>{titleOrDefault}</title>
        <meta name="description" content={descOrDefault} />
        <meta property="og:title" content={titleOrDefault} />
        <meta property="og:description" content={descOrDefault} />
        <meta property="og:image" content={metaImageUrlOrDefault} />
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        {metaPermanentUrl && <meta property="og:url" content={metaPermanentUrl} />}
        <meta property="og:type" content={metaTypeOrDefault} />
        <meta name="twitter:card" content={metaTwitterCardOrDefault} />
        {noRobots && <meta name="robots" content="noindex" />}
        {(canonicalUrl || seoPage) && !noRobots && <link rel="canonical" href={canonicalPageUrl} />}
        {facebookVerificationKey && (
          <meta name="facebook-domain-verification" content={facebookVerificationKey} />
        )}
        {googleVerificationKey && (
          <meta name="google-site-verification" content={googleVerificationKey} />
        )}
      </Head>

      {!hideLinks && showAnnouncement && (
        <Announcement
          onClickCallToAction={onClickAnnouncement}
          onClose={onCloseAnnouncement}
          callToActionText={announcement?.callToActionText}
          backgroundColor={Color.lucky.lighter}
          message={announcement?.content || ''}
        />
      )}

      {!hideHeader && (
        <Header
          headerCta={headerCta}
          headerCtaHref={headerCtaHref}
          hideLinks={hideLinks}
          logo={logo}
          hideMobileLinks={hideMobileLinks}
          bgColor={headerBgColor}
        />
      )}

      <main role="main">
        <Grid className={className} flexDirection="column">
          {children}
        </Grid>

        {!hideFinalCta && (
          <FinalCtaBlock
            externalHref={externalHref}
            primaryCta={finalCta}
            primaryHref={primaryHref}
            primaryOnClick={primaryOnClick}
            header={finalCtaHeader}
            subHeader={finalCtaSubHeader}
            gradientBackground={gradientBackground}
          />
        )}
      </main>

      {!hideFooter && <Footer />}
    </>
  )
}

export default DefaultLayout
