import { Box, Grid, IconButton, Typography } from '@mui/material'

import Anchor from '@ecm/ui/element/Anchor'
import CloudflareImage from '@ecm/ui/element/CloudflareImage'
import Color from '@ecm/ui/element/Color'
import FooterGroup from '@ecm/ui/element/FooterGroup'
import IconSection from '@ecm/ui/element/IconSection'
import MainContainer from '@ecm/ui/element/MainContainer'

import facebookIcon from '/public/images/icon/facebook.svg'
import linkedInIcon from '/public/images/icon/linked-in.svg'
import twitterIcon from '/public/images/icon/twitter.svg'

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    OneTrust: any
  }
}

const Links = {
  addresses: [
    {
      href: '/addresses/ny/new-york',
      text: 'New York Address',
    },
    {
      href: '/addresses/ca/los-angeles',
      text: 'Los Angeles Address',
    },
    { href: '/addresses/ca', text: 'California Address' },
    {
      href: '/addresses/fl/miami',
      text: 'Miami Address',
    },
    {
      href: '/addresses/tx/houston',
      text: 'Houston Address',
    },
    {
      href: '/addresses/il/chicago',
      text: 'Chicago Address',
    },
    {
      href: '/addresses/tx/dallas',
      text: 'Dallas Address',
    },
    {
      href: '/addresses/ca/san-francisco',
      text: 'San Francisco Address',
    },
    { href: '/addresses/tx', text: 'Texas Address' },
    { href: '/virtual-po-box', text: 'Virtual PO Box' },
  ],
  company: [
    { href: '/company/about', text: 'About Us' },
    { href: '/company/careers', text: 'Careers' },
    { href: '/blog', text: 'Blog' },
    { href: '/company/partnerships', text: 'Partnerships' },
    { href: '/green', text: 'Green Initiatives' },
    { href: '/case-studies', text: 'Case Studies' },
  ],
  customer: [
    {
      external: true,
      href: 'https://help.earthclassmail.com/hc/en-us/articles/206507297-Setting-Up-Your-New-Account',
      text: 'Getting Started',
    },
    { external: true, href: 'https://help.earthclassmail.com/hc/en-us', text: 'Support' },
    { external: true, href: 'https://reference.earthclassmail.com/', text: 'Developer API' },
    { href: '/contact', text: 'Contact Us' },
    { href: '/company/security', text: 'Security' },
    { href: '/how-virtual-mail-works', text: 'How Virtual Mail Works' },
    { href: '/frequently-asked-questions', text: 'Frequently Asked Questions' },
  ],
  features: [
    { href: '/check-deposit', text: 'Check Deposit' },
    { href: '/mail-scanning', text: 'Mail Scanning' },
    { href: '/mail-forwarding', text: 'Mail Forwarding' },
    { href: '/mail-shredding', text: 'Secure Shredding' },
    { href: '/integrations', text: 'Integrations' },
    { href: '/mobile', text: 'Mobile Apps' },
  ],
  products: [
    { href: '/virtual-mailbox-for-business', text: 'Virtual Mailbox for business' },
    { href: '/virtual-mailbox', text: 'Virtual Mailbox for Individuals' },
    { href: '/virtual-business-address', text: 'Virtual Business Address' },
  ],
}

const SocialMediaLinks = [
  {
    href: 'https://www.facebook.com/earthclassmail/',
    icon: facebookIcon,
    label: 'Facebook',
  },
  {
    href: 'https://www.linkedin.com/company/earth-class-mail/',
    icon: linkedInIcon,
    label: 'LinkedIn',
  },
  {
    href: 'https://twitter.com/EarthClassMail',
    icon: twitterIcon,
    label: 'Twitter',
  },
]

const Footer = () => (
  <>
    <MainContainer>
      <Grid component="footer">
        <Box
          sx={{
            display: 'grid',
            gap: '1em 3em',
            gridTemplateColumns: { md: 'repeat(5, 1fr)', sm: '1fr 1fr' },
            gridTemplateRows: 'auto',
            justifyItems: { md: 'start', xs: 'center' },
          }}
          paddingY="4em"
        >
          <Grid
            container
            sx={{
              alignItems: 'center',
              flexDirection: { md: 'column', sm: 'row', xs: 'column' },
              flexWrap: { md: 'nowrap', xs: 'wrap' },
              gridColumn: { md: '1', xs: '1 / span 2' },
              gridRow: { md: '1', xs: '3' },
              justifyContent: { md: 'flex-start', xs: 'space-between' },
            }}
          >
            <IconSection />
          </Grid>

          <Grid flexDirection="column">
            <FooterGroup heading="Products">
              {Links.products.map(({ text, ...props }) => (
                <Anchor key={text} fontWeight="400" my="0.5em" gray {...props}>
                  {text}
                </Anchor>
              ))}
            </FooterGroup>

            <FooterGroup heading="Features" margin="1em 0 0 0">
              {Links.features.map(({ text, ...props }) => (
                <Anchor key={text} fontWeight="400" my="0.5em" gray {...props}>
                  {text}
                </Anchor>
              ))}
            </FooterGroup>
          </Grid>

          <Grid flexDirection="column">
            <FooterGroup heading="Company">
              {Links.company.map(({ text, ...props }) => (
                <Anchor key={text} fontWeight="400" my="0.5em" gray {...props}>
                  {text}
                </Anchor>
              ))}
            </FooterGroup>
          </Grid>

          <Grid flexDirection="column">
            <FooterGroup heading="Customer Care">
              {Links.customer.map(({ text, ...props }) => (
                <Anchor key={text} fontWeight="400" my="0.5em" gray {...props}>
                  {text}
                </Anchor>
              ))}
            </FooterGroup>
          </Grid>

          <Grid flexDirection="column">
            <FooterGroup heading="Addresses">
              {Links.addresses.map(({ text, ...props }) => (
                <Anchor key={text} fontWeight="400" my="0.5em" gray {...props}>
                  {text}
                </Anchor>
              ))}
            </FooterGroup>
          </Grid>
        </Box>
      </Grid>
    </MainContainer>

    <Grid bgcolor={Color.smoke.normal}>
      <MainContainer
        sx={{
          alignItems: 'center',
          display: 'grid',
          gridTemplateColumns: { md: '3fr 1fr', xs: '1fr' },
          justifyItems: 'center',
          paddingY: '1em',
        }}
      >
        <Grid
          container
          gap="1em"
          sx={{
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            paddingX: { md: '3em', sm: 0 },
            width: '90%',
          }}
        >
          <Anchor href="/privacy">Privacy</Anchor>
          <Anchor
            href=""
            role="button"
            onClick={() => {
              window.OneTrust && window.OneTrust.ToggleInfoDisplay()
            }}
          >
            My information sharing settings
          </Anchor>
          <Anchor href="/terms-of-service">Terms</Anchor>
          <Typography variant="p3">{`©${new Date().getFullYear()} Earth Class Mail`}</Typography>
        </Grid>

        <Grid container justifyContent="center" wrap="nowrap" margin="1em 0">
          {SocialMediaLinks.map(({ icon, label, href }) => (
            <IconButton key={label} href={href} aria-label={label} rel="nofollow">
              <CloudflareImage src={icon} width={72} height={24} />
            </IconButton>
          ))}
        </Grid>
      </MainContainer>
    </Grid>
  </>
)

export default Footer
