import React from 'react'
import { styled } from '@mui/material'

type CloseButtonProps = {
  className?: string
  fontSize?: string
  onClose: () => void
}

export const CloseButton = styled(({ className, onClose }: CloseButtonProps) => {
  return (
    <div className={className} onClick={() => onClose()}>
      &times;
    </div>
  )
})(({ fontSize }: CloseButtonProps) => ({
  backgroundColor: 'inherit',
  border: 'none',
  color: 'inherit',
  cursor: 'pointer',
  display: 'inline-block',
  fontSize: fontSize || '2em',
  overflow: 'hidden',
  padding: '2px 10px',
  textAlign: 'center',
  textDecoration: 'none',
  verticalAlign: 'middle',
  whiteSpace: 'nowrap',
}))

export default CloseButton
