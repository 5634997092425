import React from 'react'
import { Grid, Typography } from '@mui/material'
import Link from 'next/link'

import Button from '@ecm/ui/element/Button'
import Color from '@ecm/ui/element/Color'

type FinalCtaBlockProps = {
  externalHref?: string
  header?: string
  gradientBackground?: boolean
  primaryCta?: string
  primaryHref?: string
  primaryOnClick?: () => void
  secondaryCta?: string
  secondaryCtaId?: string
  secondaryHref?: string
  subHeader?: string
}

const FinalCtaBlock = ({
  externalHref,
  header = 'Find a new way to access your postal mail.',
  gradientBackground,
  primaryCta = 'Choose a plan today',
  primaryHref = '/pricing',
  primaryOnClick,
  subHeader,
}: FinalCtaBlockProps) => (
  <Grid
    container
    component="article"
    alignItems="center"
    justifyContent="space-around"
    minHeight="20em"
    sx={{
      background: gradientBackground
        ? 'linear-gradient(273.02deg, #4EDF97 -4.11%, #0F2272 100%)'
        : Color.saint.normal,
      padding: { md: '4em', xs: '2em 1em' },
    }}
  >
    <Grid container flexDirection="column" alignItems="center" maxWidth="60em">
      <Typography variant="h3" component="h2" textAlign="center" color={Color.white.normal}>
        {header}
      </Typography>
      {subHeader && (
        <Typography variant="p3" color={Color.white.normal} my="0.5em">
          {subHeader}
        </Typography>
      )}
      <Grid
        container
        item
        flexWrap="wrap"
        alignItems="center"
        justifyContent="center"
        id={primaryCta === 'Sign up' ? 'signup-above-footer' : 'choose-a-plan'}
      >
        {externalHref && (
          <Button
            white
            margin="1em"
            onClick={() => {
              window.location.href = externalHref
            }}
          >
            {primaryCta}
          </Button>
        )}
        {!externalHref && !primaryOnClick && (
          <Link passHref href={primaryHref}>
            <Button white margin="1em">
              {primaryCta}
            </Button>
          </Link>
        )}
        {!externalHref && primaryOnClick && (
          <Button white margin="1em" onClick={primaryOnClick}>
            {primaryCta}
          </Button>
        )}
      </Grid>
    </Grid>
  </Grid>
)

export default FinalCtaBlock
