import { IconButton } from '@mui/material'
import Link from 'next/link'

import CloudflareImage from '@ecm/ui/element/CloudflareImage'

import appStoreBadge from '/public/images/icon/app-store-badge.svg'
import googlePlayBadge from '/public/images/icon/google-play-badge.svg'
import ecmNoTextLogo from '/public/images/logo/ecm-no-text.svg'

const IconSection = () => (
  <>
    <Link passHref href="/">
      <IconButton aria-label="Homepage">
        <CloudflareImage src={ecmNoTextLogo} />
      </IconButton>
    </Link>
    <IconButton
      aria-label="app-store-badge"
      rel="noopener nofollow"
      target="_blank"
      href="https://apps.apple.com/app/apple-store/id1484077329?pt=119702745&ct=website_footer&mt=8"
    >
      <CloudflareImage src={appStoreBadge} width={110} />
    </IconButton>
    <IconButton
      aria-label="google-play-badge"
      rel="noopener nofollow"
      target="_blank"
      href="https://play.google.com/store/apps/details?id=io.delivered.ecm&hl=en&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
    >
      <CloudflareImage src={googlePlayBadge} width={135} height={40} />
    </IconButton>
  </>
)

export default IconSection
