import { Grid, Typography } from '@mui/material'

import Color from '@ecm/ui/element/Color'

type FooterGroupProps = {
  children: React.ReactNode
  heading: string
  margin?: string
}

const FooterGroup = ({ children, heading, margin }: FooterGroupProps) => (
  <Grid container flexDirection="column" margin={margin || '0'}>
    <Typography variant="p3" color={Color.saint.normal} my="0.5em" fontWeight={800}>
      {heading}
    </Typography>
    {children}
  </Grid>
)

export default FooterGroup
